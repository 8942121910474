import mixins from 'vue-typed-mixins'
import CommonBookingActions from '@/calendesk/sections/section/shared/mixins/CommonBookingActions'
import { Moment } from 'moment/moment'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import SelectableService from '@/calendesk/models/SelectableService'
import SelectableEmployee from '@/calendesk/models/SelectableEmployee'
import SelectableLocation from '@/calendesk/models/SelectableLocation'
import BookingRawSlot from '@/calendesk/models/BookingRawSlot'
import BookingEvent from '@/calendesk/models/BookingEvent'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import parseRawBookingTimeSlots from '@/calendesk/tools/parseRawBookingTimeSlots'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import { errorNotification } from '@/calendesk/prototypes/notifications'

export default mixins(CommonBookingActions).extend({
  data: () => ({
    showDatePicker: false as boolean,
    availableTimeSlots: null as Record<string, any> | null,
    initialFirstAvailableDate: null as string | null,
    expandedHours: false
  }),
  watch: {
    selectedDate (newDate: string, oldDate: string) {
      if (newDate !== oldDate && this.numberOfHoursForDate(newDate) === 0) {
        this.reloadEventsDebounce()
      }
    },
    shouldReload (reload: boolean) {
      if (reload) {
        this.reloadEventsDebounce()
        this.setReloadAllBookings(false)
        this.bookingCartSlots = []
      }
    },
    customerTimeZone (newTz: string | null, oldTz: string | null) {
      if (newTz !== oldTz) {
        this.reloadEventsDebounce()
      }
    },
    preferredEmployeeIdFromTheLastBooking (val) {
      if (this.selectedSelectableService) {
        let selectableEmployee = null

        if (val) {
          const found = this.getSelectableEmployees
            .find((selectableEmployee: SelectableEmployee) => selectableEmployee.employee?.id === val)

          if (found) {
            selectableEmployee = found
          }
        }

        if (!selectableEmployee) {
          if (this.getSelectableEmployees.length === 2 && this.getSelectableEmployees[0].isAny) {
            selectableEmployee = this.getSelectableEmployees[1]
          } else {
            selectableEmployee = new SelectableEmployee(null, true, this.serviceEmployees)
          }
        }

        this.selectEmployee(selectableEmployee)
      }
    }
  },
  computed: {
    getStartDate (): Moment {
      if (this.getNumberOfDaysToDisplay === 7) {
        return this.$moment(this.selectedDate, DateTimeFormats.FULL).startOf('week')
      }

      return this.$moment(this.selectedDate, DateTimeFormats.FULL)
    },
    getEndDate (): Moment {
      if (this.getNumberOfDaysToDisplay === 7) {
        return this.$moment(this.selectedDate, DateTimeFormats.FULL).endOf('week')
      }

      return this.$moment(this.selectedDate, DateTimeFormats.FULL)
        .add(this.getNumberOfDaysToDisplay - 1, 'days')
    },
    showNoSlotsError (): boolean {
      return !this.hasAvailableSlotsInRange
    },
    showExpandCollapseButton (): boolean {
      return this.data.configuration.wb_show_expand_collapse_button__checkbox__ &&
        !this.showNoSlotsError &&
        this.getNumberOfSlotsForLongestDay > 6
    },
    getNumberOfSlotsForLongestDay (): number {
      const slotsPerDay: Record<string, Set<string>> = {}
      const startDate = this.getStartDate
      const endDate = this.getEndDate

      if (this.availableTimeSlots) {
        for (const employeeId in this.availableTimeSlots) {
          if (Object.prototype.hasOwnProperty.call(this.availableTimeSlots, employeeId)) {
            for (const date in this.availableTimeSlots[employeeId]) {
              if (Object.prototype.hasOwnProperty.call(this.availableTimeSlots[employeeId], date)) {
                const currentDate = this.$moment(date, DateTimeFormats.FULL)
                if (currentDate.isBetween(startDate, endDate, undefined, '[]')) {
                  const dateValues = this.availableTimeSlots[employeeId][date]

                  if (dateValues && dateValues.length > 0) {
                    if (!slotsPerDay[date]) {
                      slotsPerDay[date] = new Set()
                    }
                    dateValues.forEach((slot: any) => {
                      slotsPerDay[date].add(slot.time)
                    })
                  }
                }
              }
            }
          }
        }
      }

      return Math.max(...Object.values(slotsPerDay).map(times => times.size))
    },
    getNumberOfDaysToDisplay (): number {
      if (this.isUsingSmallScreen) {
        return 3
      } else if (!this.$vuetify.breakpoint.mdAndUp) {
        return 5
      }

      return 7
    },
    hasAvailableSlotsInRange (): boolean {
      if (!this.availableTimeSlots && !this.firstAvailableDate) {
        return false
      }

      const availableTimeSlots = this.availableTimeSlots as Record<string, any>
      const start = this.getStartDate
      const end = this.getEndDate

      return Object.keys(availableTimeSlots).some(employeeId => {
        return Object.keys(availableTimeSlots[employeeId]).some(date => {
          const dateMoment = this.$moment(date, DateTimeFormats.FULL)
          return dateMoment.isBetween(start, end, undefined, '[]') &&
            availableTimeSlots[employeeId][date].some((slot: any) => !slot.booked)
        })
      })
    },
    getAllRangeDates (): Array<Record<string, any>> {
      const result = []
      if (this.getStartDate) {
        for (const n in [...Array(this.getNumberOfDaysToDisplay).keys()]) {
          const dateClone = this.getStartDate.clone().add(n, 'days') as Moment
          result.push({
            instance: dateClone,
            date: dateClone.format(DateTimeFormats.FULL),
            dayShort: dateClone.format('ddd'),
            dayNumber: dateClone.format('D'),
            monthShort: dateClone.format('MMM')
          })
        }
      }

      return result
    },
    getCurrentLocationId (): number | null {
      if (this.selectedSelectableLocation && this.selectedSelectableService) {
        const getFirstServiceAssignedLocation = this.selectedSelectableLocation.getFirstServiceAssignedLocation(this.selectedSelectableService)
        return getFirstServiceAssignedLocation ? getFirstServiceAssignedLocation.locationId : null
      }

      return null
    },
    isUsingSmallScreen (): boolean {
      return this.$vuetify.breakpoint.width <= 550
    },
    getCalendarRangeInfo (): string {
      const startDate = this.getStartDate.format('Do MMM')
      const endDate = this.getEndDate.format('Do MMM YYYY')
      return startDate + ' - ' + endDate
    },
    getSelectLocationsTitle (): string {
      return this.data.configuration.wb_select_locations_title_label__html_text__
    },
    getSelectServicesTitle (): string {
      return this.data.configuration.wb_select_services_title_label__html_text__
    },
    getSelectEmployeesTitle (): string {
      return this.data.configuration.wb_select_employees_title_label__html_text__
    },
    firstAvailableDate (): string | null {
      const dates: Set<string> = new Set()
      if (this.availableTimeSlots) {
        const selectedEmployeeId = this.selectedSelectableEmployee?.getId()

        if (selectedEmployeeId && this.availableTimeSlots[selectedEmployeeId]) {
          for (const key of Object.keys(this.availableTimeSlots[selectedEmployeeId] as {})) {
            const hours = this.availableTimeSlots[selectedEmployeeId][key]
            if (hours && hours.some((slot: any) => !slot.booked)) {
              dates.add(key)
            }
          }
        } else {
          for (const employeeId of Object.keys(this.availableTimeSlots as {})) {
            for (const key of Object.keys(this.availableTimeSlots[employeeId] as {})) {
              const hours = this.availableTimeSlots[employeeId][key]
              if (hours && hours.some((slot: any) => !slot.booked)) {
                dates.add(key)
              }
            }
          }
        }
      }

      const result = [...dates].sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime()
      })

      if (result.length > 0) {
        return result[0]
      }

      return null
    }
  },
  methods: {
    toggleExpand () {
      this.expandedHours = !this.expandedHours
    },
    getTimeSlotsForDateToDisplayUI (date: string): Array<BookingTimeSlot | string> {
      const realTimeSlots = this.getTimeSlotsForDate(date)
      const maxSlots = this.getNumberOfSlotsForLongestDay
      const finalSlots: Array<BookingTimeSlot | string> = [...realTimeSlots]

      // Calculate the number of missing slots and add dashes
      const missingSlotsCount = maxSlots - realTimeSlots.length
      for (let i = 0; i < missingSlotsCount; i++) {
        finalSlots.push('-')
      }

      return finalSlots
    },
    getTimeSlotsForDate (date: string): Array<BookingTimeSlot> {
      let slots: BookingRawSlot[] = []

      if (this.availableTimeSlots) {
        const selectedEmployeeId = this.selectedSelectableEmployee?.getId()
        if (selectedEmployeeId) {
          if (this.availableTimeSlots[selectedEmployeeId] && this.availableTimeSlots[selectedEmployeeId][date]) {
            const dateValues = this.availableTimeSlots[selectedEmployeeId][date]
            if (dateValues && dateValues.length > 0) {
              slots = [
                ...slots,
                ...dateValues.map((slot: BookingRawSlot) => ({
                  ...slot,
                  date,
                  availableEmployeeIds: [selectedEmployeeId]
                }))]
            }
          }
        } else {
          for (const key of Object.keys(this.availableTimeSlots as {})) {
            const dateValues = this.availableTimeSlots[key][date]
            if (dateValues && dateValues.length > 0) {
              slots = [
                ...slots,
                ...dateValues.map((slot: BookingRawSlot) => (
                  {
                    ...slot,
                    date,
                    availableEmployeeIds: [parseInt(key, 10)]
                  }))
              ]
            }
          }
        }

        return parseRawBookingTimeSlots(slots, this.serviceMaxPeople)
      }

      return []
    },
    handleSelectableLocationChange (selectableLocation: SelectableLocation) {
      this.selectedSelectableLocation = selectableLocation
      this.initialFirstAvailableDate = null
      this.availableTimeSlots = null
      this.selectedDate = this.requestedDate ? this.requestedDate : this.$moment().format(DateTimeFormats.FULL)
      this.expandedHours = false
    },
    handleSelectableServiceChange (selectableService: SelectableService) {
      this.selectedSelectableService = selectableService
      this.reloadPreferredEmployee()
      this.initialFirstAvailableDate = null
      this.availableTimeSlots = null
      this.selectedDate = this.requestedDate ? this.requestedDate : this.$moment().format(DateTimeFormats.FULL)
      this.expandedHours = false

      if (this.selectedSelectableService && this.areEmployeesHidden) {
        this.reloadEventsDebounce()
      }
    },
    handleSelectableEmployeeChange (selectableEmployee: SelectableEmployee) {
      this.selectedSelectableEmployee = selectableEmployee
      this.initialFirstAvailableDate = null
      this.availableTimeSlots = null
      this.selectedDate = this.requestedDate ? this.requestedDate : this.$moment().format(DateTimeFormats.FULL)
      this.expandedHours = false

      if (this.selectedSelectableEmployee) {
        this.reloadEventsDebounce()
      }
    },
    calendarReloadWithPrev () {
      this.selectedDate = this.$moment(this.getStartDate, DateTimeFormats.FULL)
        .subtract(this.getNumberOfDaysToDisplay, 'days')
        .format(DateTimeFormats.FULL)
      this.reloadEventsDebounce()
    },
    calendarReloadWithNext () {
      this.selectedDate = this.$moment(this.getStartDate, DateTimeFormats.FULL)
        .add(this.getNumberOfDaysToDisplay, 'days')
        .format(DateTimeFormats.FULL)
      this.reloadEventsDebounce()
    },
    selectEmployee (selectableEmployee: SelectableEmployee) {
      this.selectEmployeeInPanels(selectableEmployee)
      this.handleSelectableEmployeeChange(selectableEmployee)
    },
    reloadEventsDebounce () {
      this.isFetchingTimeSlots = true
      this.debounce(this.reloadEvents, 400)()
    },
    reloadEvents () {
      if (this.isCalendarReadyToDisplay && this.selectedSelectableService && this.selectedDate) {
        this.isFetchingTimeSlots = true

        const eventsRequestStartDate = this.getEventsRequestStartDate()
        const maxBookingDaysForService = this.selectedSelectableService.service.getMaxBookingDays()
        const adjustedNumberOfDays = this.getAdjustedEventsRequestStartDate(eventsRequestStartDate, maxBookingDaysForService)

        if (adjustedNumberOfDays <= 0) {
          this.cleanEventsFetchSettings()
          this.validatePreferredEmployeeIdFromTheLastBooking(null, this.selectedSelectableEmployee?.getId(), true)
          return
        }

        this.fetchAvailableBookingSlots({
          used_slots: 1,
          booked_slots: 1,
          number_of_days: adjustedNumberOfDays,
          service_id: this.selectedSelectableService.service.id,
          employee_ids: this.selectedSelectableEmployee?.employees.map(employee => employee.id).join(','),
          start_date: eventsRequestStartDate,
          service_type_id: this.selectedSelectableService.serviceType?.id || null,
          customer_time_zone: this.customerTimeZone,
          location_id: this.getCurrentLocationId
        }).then((response: Record<string, any>) => {
          if (this.hasAvailableTimeSlots(response, this.selectedSelectableEmployee?.getId(), true)) {
            // Got response with time slots
            this.availableTimeSlots = response
            this.cleanEventsFetchSettings()
            this.validatePreferredEmployeeIdFromTheLastBooking(this.availableTimeSlots, this.selectedSelectableEmployee?.getId(), true)

            if (!this.initialFirstAvailableDate && this.firstAvailableDate) {
              this.selectedDate = this.firstAvailableDate
              this.initialFirstAvailableDate = this.firstAvailableDate
            }
          } else if (this.canRetryEventsFetch) {
            // No available time slots, retry.
            this.fetchAttempts++
            this.currentFetchStartDate = this.getNextFetchDate

            this.reloadEvents() // Fetch again if no slots are available
          } else {
            // Giving up, no available slots after retrying.
            this.cleanEventsFetchSettings()
            this.validatePreferredEmployeeIdFromTheLastBooking(null, this.selectedSelectableEmployee?.getId(), true)
          }
        }).catch(error => {
          this.$log.info('calendarV2@fetchAvailableBookingSlots@catch', error)
          this.cleanEventsFetchSettings()
          this.validatePreferredEmployeeIdFromTheLastBooking(null, this.selectedSelectableEmployee?.getId(), true)
        })
      }
    },
    setDate (date: string) {
      this.selectedDate = date
      this.showDatePicker = false

      if (this.numberOfHoursForDate(date) === 0) {
        this.reloadEventsDebounce()
      }
    },
    numberOfHoursForDate (date: string): number {
      let result = 0

      if (this.availableTimeSlots) {
        const selectedEmployeeId = this.selectedSelectableEmployee?.getId()

        if (selectedEmployeeId) {
          if (this.availableTimeSlots[selectedEmployeeId] && this.availableTimeSlots[selectedEmployeeId][date]) {
            const dateValues = this.availableTimeSlots[selectedEmployeeId][date]
            if (dateValues && dateValues.length > 0) {
              result = this.countSpots(dateValues)
            }
          }
        } else {
          for (const key of Object.keys(this.availableTimeSlots as {})) {
            const dateValues = this.availableTimeSlots[key][date]
            if (dateValues && dateValues.length > 0) {
              result += this.countSpots(dateValues)
            }
          }
        }
      }

      return result
    },
    toggleTimeSlot (slot: BookingTimeSlot) {
      this.selectedBookingSlot = slot

      if (this.canUseCart) {
        if (!this.isUsingCartWithServiceMaxOnePeople &&
          !this.canAddToCart(slot) &&
          this.isInCart(slot)) {
          errorNotification('no_spots_left', null, false)
          return
        }

        if (this.canAddToCart(slot)) {
          this.addToCart(slot)
        } else if (this.isInCart(slot)) {
          this.removeFromCart(slot)
        }
      } else {
        this.bookSelectedTimeSlot(slot)
      }
    },
    getEmployeeForTimeSlot (slot: BookingTimeSlot): Employee|null|undefined {
      let employee: Employee|null|undefined = null

      if (this.selectedSelectableEmployee?.isAny) {
        const sameSlots = this.getTheSameSlots(slot)
        const availableEmployeeIds = slot.availableEmployeeIds.filter(id => {
          const maxBookings = slot.employeeMaxBookings[id] || 0
          const currentBookings = sameSlots.filter(cartSlot => cartSlot.event.employee.id === id).length
          return currentBookings < maxBookings
        })

        if (availableEmployeeIds.length > 0) {
          // Group employees into those already in the cart and those not
          const inCartEmployees = availableEmployeeIds.filter((id) => {
            return sameSlots.some(cartSlot => cartSlot.event.employee.id === id)
          })

          // Prefer employees already in the cart
          const preferredEmployees = inCartEmployees.length > 0 ? inCartEmployees : availableEmployeeIds
          const randomIndex = Math.floor(Math.random() * preferredEmployees.length)
          const randomEmployeeId = preferredEmployees[randomIndex]
          employee = this.getEmployeeById(randomEmployeeId)
        }
      } else {
        employee = this.selectedSelectableEmployee?.employee
      }

      return employee
    },
    bookSelectedTimeSlot (slot: BookingTimeSlot) {
      const employee = this.getEmployeeForTimeSlot(slot)

      if (employee && this.selectedSelectableService) {
        const event = new BookingEvent(
          this.selectedSelectableService.service,
          employee,
          this.selectedSelectableService.serviceType,
          slot.date as string,
          slot.time as string,
          null,
          null,
          null,
          this.customerTimeZone,
          this.selectedSelectableEmployee?.manuallySelected,
          this.selectedSelectableLocation?.getFirstServiceAssignedLocation(this.selectedSelectableService)
        )

        this.openDialog(new Dialog(
          true,
          DialogTypes.BOOKING_FORM_DIALOG,
          null,
          DialogSize.MIDDLE,
          false,
          'center',
          {
            events: [event],
            configurationData: this.data
          },
          true))
      }
    },
    addToCart (slot: BookingTimeSlot) {
      if (!this.canAddToCart(slot)) {
        errorNotification('no_spots_left', null, false)
        return
      }

      if (this.selectedSelectableService) {
        const employee = this.getEmployeeForTimeSlot(slot)

        if (employee) {
          const event = new BookingEvent(
            this.selectedSelectableService.service,
            employee,
            this.selectedSelectableService.serviceType,
            slot.date as string,
            slot.time as string,
            null,
            null,
            null,
            this.customerTimeZone,
            this.selectedSelectableEmployee?.manuallySelected,
            this.selectedSelectableLocation?.getFirstServiceAssignedLocation(this.selectedSelectableService)
          )

          this.bookingCartSlots.push({
            key: this.getKeyForSlot(slot),
            event: event
          })
        }
      }
    },
    removeFromCart (slot: BookingTimeSlot) {
      return this.removeFromCartByKey(this.getKeyForSlot(slot))
    },
    removeFromCartByKey (key: string): void {
      const index = this.bookingCartSlots.findIndex(item => item.key === key)

      if (index !== -1) {
        this.bookingCartSlots.splice(index, 1)
      }
    },
    addNewBookingToCard () {
      const calendarPanels = this.$refs['calendar-panels'] as any

      if (this.getSelectableLocations.length > 0) {
        if (calendarPanels && typeof calendarPanels.locationSelected === 'function') {
          calendarPanels.locationSelected(null)
        }
      } else {
        if (calendarPanels && typeof calendarPanels.serviceSelected === 'function') {
          calendarPanels.serviceSelected(null)
        }
      }
    },
    selectEmployeeInPanels (selectableEmployee: SelectableEmployee | null) {
      const calendarPanels = this.$refs['calendar-panels'] as any

      if (calendarPanels && typeof calendarPanels.employeeSelected === 'function') {
        calendarPanels.employeeSelected(selectableEmployee)
      }
    }
  }
})
